/* global gtag_report_form_submission */
/* global gtag_report_page_view */

// 1. React and Hooks
import React, { useEffect, useState } from 'react';

// 2. Third-Party Libraries
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import placeholderUrl from "../../assets/placeholder.png";
import { RiCloseLargeFill } from "react-icons/ri";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// 3. Local Components
import FeatureTile from '../../components/FeatureTile/FeatureTile';

// 4. Assets (Images, etc.)

// 5. Styles
import './ItemPage.css';

const ItemPage = () => {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // Helper function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  // Fetch parameters from the URL
  const selectedType = query.get('selected_type');
  const itemId = query.get('id');

  // State for the item details
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusPosition, setStatusPosition] = useState(null);

  // Carousel state
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fetch item details based on the selected type and id
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t('offer-page-document-title');

    const fetchItemDetails = async () => {
      try {
        const endpoint = selectedType === 'NOVOGRADNJA' ? 'get_project_details' : 'get_item_details';
        const response = await fetch(`${API_URL}/${endpoint}?selected_type=${selectedType}&id=${itemId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': API_KEY
          }
        });
    
        if (!response.ok) {
          throw new Error(t("item-page-failed-to-fetch"));
        }
    
        const data = await response.json();
        setItem(data);
        
        if (data.address.city === "Kopaonik")
          gtag_report_page_view();
      } catch (err) {
        setError(err.message);
      }
    };

    fetchItemDetails();
  }, [API_URL, selectedType, itemId]);

  // Function to send the interest report
  const reportInterest = (source) => {
    let name, phone;
  
    // Determine which input fields to read based on the source
    if (source === 1) {
      name = document.getElementById('name-field-1').value;
      phone = document.getElementById('phone-field-1').value;
      setStatusPosition(1);
    } else if (source === 2) {
      name = document.getElementById('name-field-2').value;
      phone = document.getElementById('phone-field-2').value;
      setStatusPosition(2);
    }

    if (!name.trim() || !phone.trim()) {
      setStatusMessage("Molimo vas da popunite sva obavezna polja.");
      setStatusType("error");
      return;
    }

    const formData = {
      name,
      phone,
      property_link: window.location.href,
      address: `${item.address.street_address} ${item.address.street_number}, ${item.address.settlement}, ${item.address.city}`,
      selected_type: selectedType,
      price: item.price,
      title: item.page_data.title
    };

    console.log(formData);

    fetch(`${API_URL}/report_interest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': API_KEY,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setStatusMessage(t("item-page-success"));
          setStatusType('success');
          if (item.address.city === "Kopaonik")
            gtag_report_form_submission();
        } else {
          setStatusMessage(t("item-page-failure"));
          setStatusType('error');
        }
      })
      .catch((error) => {
        console.error('Error sending report:', error);
        setStatusMessage(t("item-page-failure"));
        setStatusType('error');
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      const floatingWindow = document.querySelector('.floating-window');
      const container = document.querySelector('.floating-window-container');

      if (floatingWindow && container) {
        const floatingWindowHeight = floatingWindow.offsetHeight;
        const topPosition = container.getBoundingClientRect().top + window.scrollY;
        const bottomPosition = container.getBoundingClientRect().bottom + window.scrollY;

        const maxTop = topPosition;
        const maxBottom = bottomPosition - floatingWindowHeight;

        if (window.scrollY >= maxBottom - 50) {
          floatingWindow.style.position = 'absolute';
          floatingWindow.style.top = `${maxBottom}px`;
        } else if (window.scrollY <= maxTop - 50) {
          floatingWindow.style.position = 'absolute';
          floatingWindow.style.top = `${maxTop}px`;
        } else {
          floatingWindow.style.position = 'fixed';
          floatingWindow.style.top = '50px';
        }
      }
    };

    // Add scroll listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!item) {
    return (
      <div className='loading'>
        {t("item-page-loading")}
        <span className="loading-dots">...</span>
      </div>
    );
  }

  // Open carousel with an image
  const openCarousel = (index) => {
    setCurrentImageIndex(index);
    setIsCarouselOpen(true);
  };

  // Close carousel
  const closeCarousel = () => {
    setIsCarouselOpen(false);
  };

  return (
    <>
      <div className='container darker-background'>
        <div className='image-grid'>
          <div className='grid-image'>
            <img
              src={API_URL + item.images_urls[0]}
              alt={''}
              onError={(e) => {
                e.target.src = placeholderUrl;
              }}
            />
          </div>
          <div className='small-images'>
            {item.images_urls.slice(1, 5).map((url, index) => (
              <div className='grid-image' key={index}>
                <img
                  src={API_URL + url}
                  alt={''}
                  onError={(e) => { e.target.src = placeholderUrl; }}
                />
                {index === 3 && (
                  <button className="image-overlay-button" onClick={() => openCarousel(0)}>+</button>
                )}
              </div>
            ))}
          </div>
        </div>
        <h1>{item.page_data.title}</h1>
        <div className='main-features-bar'>
          <div className='special-features-bar'>
            {Object.entries(item.special_features).slice(0, 3).map(([key, value], index) => (
              <FeatureTile key={index} featureKey={key} featureValue={value} />
            ))}
          </div>
          <div className='address'>
            <FeatureTile featureKey={'address'} featureValue={item.address} />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='item-content'>
          <div className='info-column'>
            <div className="section-header">
              <span className="section-tag">{t("item-page-about-estate")}</span>
              <span className="section-tag-line"></span>
              <h2>{item.page_data.about_title}</h2>
              <p>{item.page_data.about_text}</p>
            </div>

            <div className="section-header">
              <span className="section-tag">{t("item-page-key-features")}</span>
              <span className="section-tag-line"></span>
              <ul className="highlighted-list">
                {item.page_data.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>

            <div className="section-header">
              <span className="section-tag">{t("item-page-features")}</span>
              <span className="section-tag-line"></span>
              <div className='item-features'>
                {Object.entries(item.special_features).map(([key, value], index) => (
                  <FeatureTile key={index} featureKey={key} featureValue={value} />
                ))}
                {Object.entries(item.other_features).map(([key, value], index) => (
                  <FeatureTile key={index} featureKey={key} featureValue={value} />
                ))}
              </div>
            </div>

            <div className="section-header">
              <span className="section-tag">{t("item-page-location")}</span>
              <span className="section-tag-line"></span>
              <h2>{item.page_data.location_title}</h2>
              <p>{item.page_data.location_text}</p>
            </div>
          </div>

          {/* Floating contact form */}
          <div className='floating-window-container'>
            <div className='floating-window'>
              <div className="price-section">
                <h1 className="price">
                  {typeof item.price === 'number' 
                    ? `${Intl.NumberFormat('de-DE').format(item.price)} €` 
                    : 'Cena na upit'}
                </h1>
                <span className="provision">(+0% {t("item-page-provision")})</span>
              </div>

              <Link to="/kontakt" className="action-button inverse-colors">{t("item-page-contact-us")}</Link>

              <div className="divider">{t("item-page-or")}</div>

              <div className="input-section">
                <input id="name-field-1" type="text" placeholder={t("item-page-name")} className="input-field" />
                <input id="phone-field-1" type="tel" placeholder={t("item-page-phone")} className="input-field" />
              </div>

              <button type="button" className='action-button inverse-colors' onClick={() => reportInterest(1)}>
                {t("item-page-report-interest")}
              </button>

              {statusMessage && statusPosition === 1 && (
                <div className={`status-message ${statusType}`}>
                  {statusMessage}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="section-header is-this-for-you">
          <span className="section-tag">{t("item-page-conclusion")}</span>
          <span className="section-tag-line"></span>
          <div className='two-column-container'>
            <div className='text-column'>
              <h2>{t("item-page-is-this-for-you")}</h2>
              <p>{item.page_data.is_it_for_you_text}</p>
              <div className='call-to-action'>
                <Link to="/kontakt" className="action-button">{t("item-page-contact-us")}</Link>

                <div className="divider">{t("item-page-or")}</div>

                <div className="input-section">
                  <input id="name-field-2" type="text" placeholder={t("item-page-name")} className="input-field" />
                  <input id="phone-field-2" type="tel" placeholder={t("item-page-phone")} className="input-field" />
                </div>

                <button type="button" className='action-button' onClick={() => reportInterest(2)}>
                  {t("item-page-report-interest")}
                </button>

                {statusMessage && statusPosition === 2 && (
                  <div className={`status-message ${statusType}`}>
                    {statusMessage}
                  </div>
                )}
              </div>
            </div>

            <div className='grid-image'>
              <img
                src={API_URL + item.images_urls[0]}
                alt={''}
                onError={(e) => {
                  e.target.src = placeholderUrl;
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Carousel Overlay */}
      {isCarouselOpen && (
        <div className="carousel-overlay">
          <button className="carousel-close-button" onClick={closeCarousel}><RiCloseLargeFill /></button>
          <div className="carousel-content">
            <div className="carousel-image">
              <img
                src={API_URL + item.images_urls[currentImageIndex]}
                alt="Carousel"
              />
            </div>

            <button className="carousel-nav prev" onClick={() => setCurrentImageIndex((currentImageIndex - 1 + item.images_urls.length) % item.images_urls.length)}>
              <FaChevronLeft />
            </button>
            <button className="carousel-nav next" onClick={() => setCurrentImageIndex((currentImageIndex + 1) % item.images_urls.length)}>
              <FaChevronRight />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ItemPage;
